<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      show-empty
      :fields="fields"
      :items="items"
      :busy="isBusy"
      hover
      :responsive="true"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary
          :item="morphItem(data.item.employee)"
        ></CommonEmployeeSummary>
      </template>

      <!-- begin:: Status Cell     -->
      <template v-slot:cell(status)="data">
        <span
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ data.item.status }}</span
        >
      </template>
      <!-- end:: Status Cell     -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No Applications"
          text-bottom="..."
        ></AppEmptyList>
      </template>
    </b-table>
  </div>
</template>

<script>
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
export default {
  name: "EmployeeAwayApplicationPageTable",
  components: { AppLoader, CommonEmployeeSummary, AppEmptyList },
  props: {
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pagination: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Away Type",
          stickyColumn: true,
          variant: "light",
        },
        {
          key: "employee",
          label: this.$t("EMPLOYEE.HEADER"),
        },
        { key: "from_date", label: this.$t("EDUCATION.FROM") },
        { key: "to_date", label: this.$t("EDUCATION.TO") },
        { key: "unit.requested", label: this.$t("HOLIDAY.DAY") },
        { key: "reason", label: this.$t("ENTITLEMENT.REASON") },
        { key: "status", label: this.$t("JOB.STATUS") },
      ],
    };
  },
  methods: {
    morphItem(item) {
      return {
        name: item.first_name,
        code: item.unique_code,
        email: item.email,
        image_url: item.image_url,
      };
    },
  },
};
</script>

<style scoped></style>
