<template>
  <AppPageCard>
    <template #card-title> Company Away Application </template>
    <template #card-toolbar>
      <!-- begin: refresh button  -->
      <b-button
        variant="primary"
        class="btn btn-icon btn-light btn-hover-primary ml-4"
        v-on:click="refresh"
      >
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </b-button>
      <!-- end: refresh button  -->
    </template>
    <EmployeeAwayApplicationPageTable
      :items="items"
      :is-busy="isBusy"
      :pagination="pagination"
    ></EmployeeAwayApplicationPageTable>
  </AppPageCard>
</template>

<script>
import { mapGetters } from "vuex";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import EmployeeAwayApplicationPageTable from "@/modules/company/components/away/application/EmployeeAwayApplicationPageTable";
export default {
  name: "EmployeeAwayApplicationPage",
  components: { EmployeeAwayApplicationPageTable, AppPageCard },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    refresh() {
      this.fetch();
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeAwayApplicationApprovalList")
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {
    ...mapGetters({
      items: "getEmployeeAwayApplicationApprovalList",
      pagination: "getEmployeeAwayApplicationApprovalPagination",
    }),
  },
};
</script>

<style scoped></style>
